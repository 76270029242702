import { format } from "date-fns"
import Link from "next/link"
import { Logo } from '../svgs/logo'

export type SiteFooterProps = {
	appData: any,
}

const SiteFooter = (props: SiteFooterProps) => {

	if (!props.appData) {
		return null
	}

	return (
		<footer className="site-footer">

			<div className="site-footer__wrapper">
				<div className={`site-footer__branding`}>
					<Logo />
				</div>
			</div>

			<nav className="footer-navigation">
				<ul className="footer-navigation__list">
					{props.appData.footerNavigation.map((topLevel: any) => {
						return (
							<li key={topLevel.id} className="footer-navigation__toplevel-item">
								{topLevel.element ? (
									<Link href={`/${topLevel.element.uri}`} className="footer-navigation__toplevel-link">{topLevel.title}</Link>
								) : (
									<span className="footer-navigation__toplevel-link">{topLevel.title}</span>
								)}
								{topLevel.children?.length > 0 ? (
									<ul className="footer-navigation__child-list">
										{topLevel.children.map((child: any) => {
											return (
												<li key={child.id} className="footer-navigation__child-item">
													{child.element ? (
														<Link href={`/${child.element.uri}`} className="footer-navigation__child-link">{child.title}</Link>
													) : child.url ? (
														<a href={`${child.url}`} target="_blank" rel="noreferrer">{child.title}</a>
													) : (
														<span>{child.title}</span>
													)}
												</li>
											)
										})}
									</ul>
								) : null}
							</li>
						)
					})}
				</ul>
			</nav>

			<div className="site-footer__wrapper">
				<div className="site-footer__copyright">
					<p>{`© ${format(new Date(), 'yyyy')} Arctic Group`}</p>
				</div>
			</div>

			<div className="decor-lines">
				<svg width="252" height="415" viewBox="0 0 252 415" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M251.434 73.6133C95.9817 127.094 28.5145 275.086 0.491364 414.173M251.434 0.953613C148.707 152.692 137.858 294.309 172.118 414.173" stroke="#00E6FF"/>
				</svg>
			</div>
		</footer>
	)

}
export default SiteFooter
