import { useState } from "react"

import CompanySubHeader from './CompanySubHeader'

import SiteFooter from './SiteFooter'
import SiteHeader from './SiteHeader'
import SubHeader from './SubHeader'
import { BackToTop } from '../svgs/Icons'

interface LayoutProps {
	children: JSX.Element
	appData: any
	theme?: string
	subHeader?: Array<{
		id: number
		title: string
		uri: string|null
	}>
	companySiteData?: any
}

const Layout = ( props: LayoutProps ) => {
	const [ showBackToTopButton, setShowBackToTopButton ] = useState(false)
	const scrollLimit = 1000;

	if ( !props.appData ) {
		return null
	}

	if (typeof window !== "undefined") {
		window.addEventListener('scroll', () => onScroll());
	}

	const onScroll = () => {
		if (document.body.scrollTop > scrollLimit || document.documentElement.scrollTop > scrollLimit) {
			setShowBackToTopButton(true);
		} else {
			setShowBackToTopButton(false);
		}
	}

	const backToTop = () => {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}

    return (
		<div className={`site-container site-container--theme-${props.theme ? props.theme : 'default'}`}>
			<SiteHeader appData={props.appData} />
			{props.subHeader && <SubHeader subHeader={props.subHeader} />}
			{props.companySiteData && <CompanySubHeader companySiteData={props.companySiteData} />}
			<main className='main-content'>
				{props.children}
			</main>
			{showBackToTopButton && <button className="back-to-top-button" onClick={backToTop}><BackToTop /></button>}
			<SiteFooter appData={props.appData} />
		</div>
    )

}

export default Layout
