import Link from "next/link"
import { ChevronRight } from "../svgs/Icons"

interface SubHeaderProps {
	subHeader?: any
}

const SubHeader = (props: SubHeaderProps) => {

	const { subHeader } = props

	if (subHeader && subHeader.length === 0) {
		return null
	}

	return (
		<div className={`sub-header`}>
			<div className={`sub-header__wrapper`}>
				<nav aria-label="Breadcrumbs" className={`sub-header__breadcrumb-container`}>
					<ol className={`sub-header__breadcrumb-list`}>

						<li key="breadcrumb-home" className={`sub-header__breadcrumb-item`}>
							<div className="sub-header__breadcrumb-item-inner">
								<Link href="/">Home</Link>
								<ChevronRight />
							</div>
						</li>

						{subHeader.map((item: any) => {

							if (item.uri) {
								return (
									<li key={item.id} className={`sub-header__breadcrumb-item`}>
										<div className="sub-header__breadcrumb-item-inner">
											<Link href={`/${item.uri}`} aria-label={item.title}>
												{item.title}
											</Link>
											<ChevronRight />
										</div>
									</li>
								)
							}

							return (
								<li key={item.id} className={`sub-header__breadcrumb-item`}>{item.title}</li>
							)

						})}
					</ol>
				</nav>
			</div>
		</div>
	)

}

export default SubHeader
