import { useState } from "react"

import Link from "next/link"
import { Logo } from '../svgs/logo'
import { HamburgerMenu, CloseHamburgerMenu } from "../svgs/Icons"
import { ChevronDown, ChevronRight, Person } from "../svgs/Icons"

interface SiteHeaderProps {
	appData: any,
}

interface MobileMenuProps {
	mainNavigation: any | null,
}


const MobileMenu = (props: MobileMenuProps) => {

	const { mainNavigation } = props

	const [subNavigation, setSubNavigation] = useState<any | null>(null)
	const [subSubNavigation, setSubSubNavigation] = useState<any | null>(null)

	if (!mainNavigation || mainNavigation.length === 0) {
		return null
	}

	return (

		<nav className={`mobile-menu__navigation-container`}>
			<ul className="top-level-list">
				{mainNavigation.map((node: any) => {
					let hasChildren = node.children && node.children.length > 0 ? true : false;

					return (
						<li key={`mainnav-node-${node.id}`} className={`${hasChildren ? 'sub-navigation sub-navigation__accordion' : ''}`}>
							{hasChildren ? (
								<>
									<button
										className={`toggle-button toggle-button--${subNavigation && node.id === subNavigation.id ? 'active' : 'idle'}`}
										onClick={() => {
											if (subNavigation && subNavigation.id === node.id) {
												setSubNavigation(null)
											} else {
												setSubNavigation(node)
											}
										}}
									>
										<span className={`toggle-button__label`}>{node.title}</span>
										<span className={`toggle-button__icon`}><ChevronDown /></span>
									</button>
									<ul className={`sub-navigation__accordion--${subNavigation && node.id === subNavigation.id ? 'expanded' : 'closed'}`}>
										{subNavigation && subNavigation.element !== null &&
											<li><Link href={`/${subNavigation.element.uri}`} className="read-more arrow-link">Read more<span className="arrow">→</span></Link></li>
										}
										{node.children.map((item: any) => {
											return (										
												<li key={`subnav-node-${item.id}`}>
													{item.children && item.children.length > 0 ? (
														<>
															<button
																className={`toggle-button toggle-button--${subSubNavigation && item.id === subSubNavigation.id ? 'active' : 'idle'}`}
																onClick={() => {
																	if (subSubNavigation && subSubNavigation.id === item.id) {
																		setSubSubNavigation(null)
																	} else {
																		setSubSubNavigation(item)
																	}
																}}
															>
																<span className={`toggle-button__label`}>{item.title}</span>
																<span className={`toggle-button__icon`}><ChevronDown /></span>
															</button>
															<ul className={`sub-navigation--level-3 sub-navigation__accordion--${subSubNavigation && item.id === subSubNavigation.id ? 'expanded' : 'closed'}`}>
																<li><Link href={`/${item.element?.uri}`} className="arrow-link">{item.title}<span>→</span></Link></li>
																{item.children.map((child: any) => {
																	return (
																		<li key={`subsubnav-node-${child.id}`}><Link href={`/${child.element?.uri}`} className="arrow-link">{child.title}<span>→</span></Link></li>
																	)
																})}
															</ul>
														</>
													) : item.element ? (
														/*<Link href={`/${item.element.uri}`}><span className={`link__label`}>{item.title}</span><span className={``}><ChevronRight /></span></Link>*/
														<Link href={`/${item.element.uri}`} className="arrow-link">{item.title}<span>→</span></Link>
													) : (
														<span>{item.title}</span>
													)}
												</li>
											)
										})}
									</ul>
								</>
							) : (
								<Link href={`/${node.element?.uri}`}>{node.title}</Link>
							)}

						</li>
					)
				})}

				<li><Link href="/login" className="link-button login-button">
					<span className={`link-button__icon`}><Person /></span>
					<span className={`link-button__label`}>Login</span>
				</Link></li>
			</ul>
		</nav>
	)

}
 
const SiteHeader = (props: SiteHeaderProps) => {

	const { appData } = props
	const [menuOpen, setMenuOpen] = useState<boolean>(false)

	const toggleMenu = () => {
		setMenuOpen(!menuOpen);
	}

	// console.log(appData.mainNavigation)
	// console.log('menuOpen', menuOpen)

	return (
		<>
			<header className={`site-header site-header--navigation-is-${menuOpen ? 'visible' : 'hidden'}`}>
				<div className={`site-header__wrapper`}>
					<div className={`site-header__branding`}>
						<Link href="/" aria-label="Go to home page"><Logo /></Link>
					</div>
					<nav className={`site-header__navigation-container`}>
						{/*<ul>
							<li><Link href="/login" className="link-button">
								<span className={`link-button__icon`}><Person /></span>
								<span className={`link-button__label`}>Login</span>
							</Link></li>
						</ul>*/}
						<ul>
							<li><button className="toggle-mobile-menu" onClick={() => toggleMenu()}>
								{menuOpen ?
									<CloseHamburgerMenu />
								:
									<HamburgerMenu />
								}
							</button></li>
						</ul>
					</nav>
				</div>
			</header>
			{appData.mainNavigation && menuOpen &&
				<div className="mobile-menu">
					<MobileMenu mainNavigation={appData?.mainNavigation} />
				</div>
			}
		</>
	)

}
export default SiteHeader
