import Link from "next/link"
import { Logo } from '../svgs/logo'


const FallbackHeader = () => {

	return (
		<header className={`site-header`}>
			<div className={`site-header__wrapper`}>
				<div className={`site-header__branding`}>
					<Link href="/" aria-label="Go to home page"><Logo /></Link>
				</div>
			</div>
		</header>
	)

}
export default FallbackHeader
