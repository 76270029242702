import { useState, useEffect } from "react"

interface WindowSizeState {
	width: number | undefined,
	height: number | undefined
	screen: string | undefined
	orientation: string | undefined
}

function useWindowSize() {

	// Initialize state with undefined width/height so server and client renders match
	// Ref: https://joshwcomeau.com/react/the-perils-of-rehydration/
	const [windowSize, setWindowSize] = useState<WindowSizeState>({
		width: undefined,
		height: undefined,
		screen: undefined,
		orientation: undefined
	});

	useEffect(() => {

		// Handler to call on window resize
		// Set window width/height to state
		function handleResize() {
			const w = window.innerWidth
			const h = window.innerHeight
			setWindowSize({
				width: w,
				height: h,
				screen: w < 640 ? 'small' : w < 1024 ? 'medium' : 'large',
				orientation: w < h ? 'portrait' : w === h ? 'square' : 'landscape'
			});
		}

		// Add event listener
		window.addEventListener("resize", handleResize);

		// Call handler right away so state gets updated with initial window size
		handleResize();

		// Remove event listener on cleanup
		return () => window.removeEventListener("resize", handleResize);
	}, []); // Empty array ensures that effect is only run on mount

	return windowSize;

}

export default useWindowSize
