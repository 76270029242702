import { useEffect, useState, useRef } from "react"

import useWindowSize from "../../lib/hooks/useWindowSize"

import Link from "next/link"
import { Logo } from '../svgs/logo'

import { ChevronDown, Person } from "../svgs/Icons"

interface SubNavigationProps {
	subNavigation: any | null,
	setSubNavigation: any
}

const SubNavigation = (props: SubNavigationProps) => {

	const { subNavigation, setSubNavigation } = props

	if (!subNavigation || subNavigation.length === 0) {
		return null
	}

	return (
		<div key={subNavigation.id} className={`site-header__sub-navigation`}>
			<div className={`site-header__sub-navigation__wrapper`}>
				<div className={`site-header__sub-navigation__top-level`}>
					<h3>{subNavigation.title}</h3>
					<p>{subNavigation.description}</p>
					{subNavigation.element ? (
						<p><Link href={`/${subNavigation.element.uri}`} className="read-more"><span className="arrow">→</span>Overview</Link></p>
					) : null}
				</div>
				<div className="site-header__sub-navigation__links">
					{subNavigation.children.map((node: any) => {
						return (
							<div key={node.id} className={`site-header__sub-navigation__secondary-level`}>
								<h4>
									{node.element ? (
										<Link href={`/${node.element.uri}`} onClick={() => setSubNavigation(null)}>{node.title}</Link>
									) : (
										<span>{node.title}</span>
									)}
								</h4>
								{node.children && node.children.length > 0 ? (
									<ul>
										{node.children.map((subNode: any) => {
											return (
												<li key={subNode.id}>
													{subNode.element ? (
														<Link href={`/${subNode.element.uri}`} onClick={() => setSubNavigation(null)}>{subNode.title}</Link>
													) : (
														<span>{subNode.title}</span>
													)}
												</li>
											)
										})}
									</ul>
								) : null}
							</div>
						)
					})}
				</div>
			</div>
		</div>
	)

}


interface LargeNavigationContainerProps {
	mainNavigation: any // Data
	subNavigation: any | null // Data set by clicking buttons
	setSubNavigation: any // setState function on parent
}

const LargeNavigationContainer = (props: LargeNavigationContainerProps) => {

	const { mainNavigation, subNavigation, setSubNavigation } = props

	if (!mainNavigation) {
		return null
	}

	return (
		<nav className={`site-header__navigation-container`}>
			<ul>
				{mainNavigation.map((node: any) => {

					return (
						<li key={`mainnav-node-${node.id}`}>
							{node.children && node.children.length > 0 ? (
								<button
									className={`toggle-button toggle-button--${subNavigation && node.id === subNavigation.id ? 'active' : 'idle'}`}
									onClick={() => {
										if (subNavigation && subNavigation.id === node.id) {
											setSubNavigation(null)
										} else {
											setSubNavigation(node)
										}
									}}
								>
									<span className={`toggle-button__label`}>{node.title}</span>
									<span className={`toggle-button__icon`}><ChevronDown /></span>
								</button>
							) : (
								<Link href={`/${node.element?.uri}`}>{node.title}</Link>
							)}

						</li>
					)
				})}
				<li><Link href="/login" className="link-button login-button">
					<span className={`link-button__icon`}><Person /></span>
					<span className={`link-button__label`}>Login</span>
				</Link></li>
			</ul>
		</nav>
	)

}



interface LargeHeaderProps {
	appData: any,
}

const LargeHeader = (props: LargeHeaderProps) => {

	const { appData } = props

	const [subNavigation, setSubNavigation] = useState<any | null>(null)
	const menuRef = useRef<HTMLElement>(null);

	useEffect(() => {
		document.addEventListener("mousedown", handleClick);

		return () => {
			document.removeEventListener("mousedown", handleClick);
		};
	}, []);

	const handleClick = (evt:any) => {
		if (!menuRef?.current?.contains(evt.target)) {
			setSubNavigation(null);
		}
	};

	return (
		<header ref={menuRef} className={`site-header site-header--navigation-is-${subNavigation ? 'visible' : 'hidden'}`}>
			<div className={`site-header__wrapper`}>
				<div className={`site-header__branding`}>
					<Link href="/" aria-label="Go to home page"><Logo /></Link>
				</div>
				<LargeNavigationContainer
					mainNavigation={appData?.mainNavigation}
					setSubNavigation={setSubNavigation}
					subNavigation={subNavigation}
				/>
			</div>
			<SubNavigation subNavigation={subNavigation} setSubNavigation={setSubNavigation} />
		</header>
	)

}
export default LargeHeader
