import { useState, useMemo } from "react"

import useWindowSize from "../../lib/hooks/useWindowSize"

import Link from "next/link"
import { Logo } from '../svgs/logo'

import { ChevronDown, Person } from "../svgs/Icons"
import LargeHeader from "./LargeHeader"
import SmallHeader from "./SmallHeader"
import FallbackHeader from "./FallbackHeader"


interface SiteHeaderProps {
	appData: any,
}

const SiteHeader = (props: SiteHeaderProps) => {

	const windowSize = useWindowSize();

	const { appData } = props

	const header = useMemo(() => {
		switch( windowSize.screen ) {
			case 'large' :
				return <LargeHeader appData={appData} />
			case 'medium' :
				return <SmallHeader appData={appData} />
			case 'small' :
				return <SmallHeader appData={appData} />
			default:
				return <FallbackHeader />
		}
	}, [appData, windowSize.screen])

	return <>{header}</>

}
export default SiteHeader
