import { useState } from "react"

import Link from "next/link"
import { ChevronDown } from "../svgs/Icons"

interface CompanySubHeaderProps {
	companySiteData: any
}

const CompanySubHeader = (props: CompanySubHeaderProps) => {

	const { companySiteData } = props
	const { company, navigation } = companySiteData

	const [isOpen, setIsOpen] = useState(false)

	if (!companySiteData || !company) {
		return null
	}

	return (
		<div className={`company-header company-header--is-${isOpen ? 'open' : 'closed'}`}>
			<div className={`company-header__wrapper`}>

				<div className="company-header__company">
					<Link href={`/${company.uri}`}>{company.title}</Link>
				</div>

				{navigation ? (
					<>
						<div className="company-header__mobile-ui">
							<button className="company-header__toggle-button" onClick={() => {
								setIsOpen(!isOpen)
							}}>
								<ChevronDown />
							</button>
						</div>
						<nav className={`company-header__navigation`}>
							<ol className={`company-header__navigation-list`}>
								{navigation.map((node: any) => {
									if ( node.element ) {
										return (
											<li key={node.id} className={`company-header__navigation-item`}>
												<Link href={`/${node.element.uri}`}>{node.title}</Link>
											</li>
										)
									}
								})}
							</ol>
						</nav>
					</>
				) : null}

			</div>
		</div>
	)

}

export default CompanySubHeader
