export const Logo = () => {
    return (
        <svg width="141" height="27" viewBox="0 0 141 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.3685 4.60395C22.8402 5.47768 23.6055 6.87565 23.0757 7.80763C22.5459 8.73961 20.8976 8.73961 19.4259 7.86588C17.9542 6.99215 17.189 5.59418 17.7188 4.6622C18.2486 3.78847 19.8968 3.73022 21.3685 4.60395Z" fill="currentColor" />
            <path d="M17.5419 9.32199C17.2476 10.3705 16.0114 10.8947 14.834 10.5452C13.6567 10.1957 12.8914 9.089 13.1857 8.04052C13.4801 6.99204 14.7163 6.46781 15.8936 6.8173C17.1298 7.16679 17.8362 8.27352 17.5419 9.32199Z" fill="currentColor" />
            <path d="M7.71184 13.4577C7.2409 12.6422 7.5941 11.5938 8.53598 11.0695C9.41898 10.5453 10.5375 10.8365 11.0084 11.652C11.4793 12.4675 11.1261 13.516 10.1843 14.0402C9.24238 14.5062 8.18278 14.2732 7.71184 13.4577Z" fill="currentColor" />
            <path d="M15.305 18.9915C14.3042 19.0497 13.4212 18.2342 13.3623 17.244C13.3035 16.2538 14.1276 15.38 15.1284 15.3218C16.1291 15.2635 17.0121 16.079 17.071 17.0692C17.1298 18.0595 16.3057 18.9332 15.305 18.9915Z" fill="currentColor" />
            <path d="M22.1922 20.7389C21.3092 20.7971 20.544 19.9816 20.544 18.9914C20.4851 18.0012 21.1915 17.1857 22.0745 17.1275C22.9575 17.0692 23.7228 17.8847 23.7228 18.8749C23.7816 19.8651 23.0752 20.6806 22.1922 20.7389Z" fill="currentColor" />
            <path d="M27.7851 19.1663C27.1964 19.2827 26.5489 18.642 26.3134 17.7683C26.1368 16.8945 26.49 16.0208 27.0787 15.9043C27.6674 15.7878 28.3149 16.4286 28.5504 17.3023C28.727 18.2343 28.4327 19.0498 27.7851 19.1663Z" fill="currentColor" />
            <path d="M30.3757 15.4385C30.0814 15.555 29.5516 14.9725 29.2572 14.157C28.9629 13.3416 29.0218 12.6426 29.3161 12.5261C29.6104 12.4096 30.1402 12.9921 30.4346 13.8075C30.7289 14.5648 30.7289 15.322 30.3757 15.4385Z" fill="currentColor" />
            <path d="M32.2 15.9627C32.1411 15.9627 31.9645 15.6132 31.9056 15.1472C31.7879 14.6812 31.7879 14.2734 31.8468 14.2734C31.9056 14.2734 32.0822 14.6229 32.1411 15.0889C32.2588 15.5549 32.3177 15.9044 32.2 15.9627Z" fill="currentColor" />
            <path d="M5.17996 7.69106C4.9445 6.99208 5.4743 6.17659 6.35731 5.88535C7.29918 5.53586 8.24105 5.8271 8.47652 6.46784C8.71199 7.16682 8.18219 7.98231 7.29918 8.27355C6.41617 8.62304 5.41543 8.3318 5.17996 7.69106Z" fill="currentColor" />
            <path d="M6.41618 2.973C6.29845 2.56526 7.00485 2.04102 7.88786 1.86627C8.77086 1.69153 9.595 1.86627 9.65387 2.33226C9.7716 2.74001 9.0652 3.26425 8.18219 3.43899C7.35805 3.67199 6.53392 3.43899 6.41618 2.973Z" fill="currentColor" />
            <path d="M10.3018 0.468289C10.3018 0.235294 11.0082 0.0605469 11.8323 0.0605469C12.6564 0.0605469 13.3628 0.235294 13.3628 0.468289C13.3628 0.701285 12.6564 0.876031 11.8323 0.876031C11.0082 0.817782 10.3018 0.643036 10.3018 0.468289Z" fill="currentColor" />
            <path d="M28.5504 8.38991C28.727 8.27341 29.139 8.7394 29.5511 9.43839C29.9632 10.0791 30.1398 10.7199 29.9632 10.7781C29.7866 10.8946 29.3745 10.4286 28.9624 9.72963C28.5504 9.0889 28.3738 8.50641 28.5504 8.38991Z" fill="currentColor" />
            <path d="M15.4818 0.0606088C15.4818 -0.0558888 16.1882 0.00236046 16.9534 0.177107C17.7187 0.351853 18.3662 0.584848 18.3074 0.643097C18.3074 0.759594 17.601 0.701346 16.8357 0.5266C16.0704 0.351853 15.4229 0.177106 15.4818 0.0606088Z" fill="currentColor" />
            <path d="M25.3713 4.42916C25.4302 4.37091 25.96 4.7204 26.4898 5.30289C27.0196 5.82713 27.4317 6.35137 27.3728 6.40962C27.3139 6.46787 26.7841 6.11837 26.2543 5.53589C25.7245 5.01165 25.3125 4.54566 25.3713 4.42916Z" fill="currentColor" />
            <path d="M20.7805 1.51667C20.8394 1.45842 21.4281 1.69142 22.1345 2.09916C22.8409 2.44865 23.3707 2.79814 23.3707 2.85639C23.3118 2.91464 22.7231 2.68165 22.0167 2.2739C21.3103 1.92441 20.7805 1.57492 20.7805 1.51667Z" fill="currentColor" />
            <path d="M23.0756 13.6909C22.3103 14.3316 21.0153 13.9821 20.25 13.0502C19.4847 12.0599 19.4847 10.7785 20.25 10.196C21.0153 9.55524 22.3103 9.90474 23.0756 10.8367C23.8409 11.7687 23.8409 13.1084 23.0756 13.6909Z" fill="currentColor" />
            <path d="M16.3646 3.96346C16.3058 4.66245 15.2462 5.12844 14.0688 5.01194C12.8915 4.89544 11.9496 4.2547 12.0085 3.49747C12.0673 2.79849 13.127 2.3325 14.3043 2.44899C15.5405 2.62374 16.4824 3.26448 16.3646 3.96346Z" fill="currentColor" />
            <path d="M27.1965 11.4192C26.6667 11.827 25.7248 11.4192 24.9596 10.4872C24.2532 9.55527 24.0766 8.50679 24.5475 8.1573C25.0773 7.74956 26.0192 8.1573 26.7845 9.08928C27.4909 9.96301 27.6675 11.0115 27.1965 11.4192Z" fill="currentColor" />
            <path d="M20.6625 2.97282C20.5448 3.32231 19.6029 3.38056 18.5433 3.03107C17.4837 2.68158 16.7184 2.09909 16.895 1.7496C17.0128 1.40011 17.9546 1.34186 19.0142 1.69135C20.0739 2.04084 20.7803 2.62333 20.6625 2.97282Z" fill="currentColor" />
            <path d="M25.7245 6.40964C25.489 6.70088 24.606 6.40964 23.7818 5.71065C22.9577 5.06991 22.4279 4.25443 22.6634 3.96319C22.8988 3.67194 23.7818 3.96319 24.606 4.66217C25.489 5.36116 25.9599 6.11839 25.7245 6.40964Z" fill="currentColor" />
            <path d="M26.6075 25.7483C26.3721 26.2725 25.9011 26.622 25.4891 26.4473C25.077 26.2725 24.9592 25.7483 25.1947 25.1658C25.4302 24.6416 25.9011 24.2921 26.3132 24.4668C26.6664 24.6416 26.7841 25.2241 26.6075 25.7483Z" fill="currentColor" />
            <path d="M17.6004 26.564C17.1883 27.03 16.4819 27.1465 16.0698 26.797C15.5989 26.4475 15.54 25.7485 15.9521 25.2243C16.3641 24.7583 17.0706 24.6418 17.5415 24.9913C17.9536 25.399 18.0124 26.0398 17.6004 26.564Z" fill="currentColor" />
            <path d="M1.70691 13.8071C1.29484 14.2731 0.70617 14.4478 0.294101 14.1566C-0.0591013 13.8653 -0.0591011 13.2246 0.352968 12.7004C0.765038 12.2344 1.41257 12.0596 1.76578 12.3509C2.17785 12.6421 2.11898 13.2829 1.70691 13.8071Z" fill="currentColor" />
            <path d="M1.17711 6.23498C0.706173 6.58447 0.235236 6.70097 0.0586345 6.46798C-0.117967 6.23498 0.117501 5.82724 0.588438 5.47775C1.05938 5.12826 1.58918 5.01176 1.70691 5.24475C1.88351 5.4195 1.64805 5.88549 1.17711 6.23498Z" fill="currentColor" />
            <path d="M5.23925 1.34184C4.76831 1.51658 4.29737 1.57483 4.29737 1.51658C4.2385 1.45833 4.65057 1.22534 5.12151 1.05059C5.59245 0.875848 6.06339 0.817598 6.12225 0.875847C6.12225 0.992344 5.76905 1.16709 5.23925 1.34184Z" fill="currentColor" />
            <path d="M31.5521 21.1465C31.5521 21.6707 31.3167 22.0785 31.1401 22.0785C30.9046 22.0785 30.7869 21.6125 30.7869 21.0882C30.7869 20.564 31.0223 20.1562 31.1989 20.1562C31.3755 20.2145 31.5521 20.6222 31.5521 21.1465Z" fill="currentColor" />
            <path d="M8.12348 21.1464C8.12348 21.7872 7.59368 22.3114 6.94614 22.3114C6.2986 22.3114 5.7688 21.7872 5.7688 21.1464C5.7688 20.5057 6.2986 19.9814 6.94614 19.9814C7.59368 19.9814 8.12348 20.5057 8.12348 21.1464Z" fill="currentColor" />
            <path d="M43.2675 20.9718L51.1557 4.13792C51.2146 3.96318 51.3323 3.84668 51.5678 3.84668H51.8032C52.0387 3.84668 52.1564 3.96318 52.2153 4.13792L59.9857 20.9718C60.1623 21.3213 59.9269 21.6126 59.5737 21.6126H57.3956C57.0424 21.6126 56.8658 21.4378 56.6892 21.0883L55.453 18.4089H47.918L46.6818 21.0883C46.5641 21.3213 46.3286 21.6126 45.9754 21.6126H43.7973C43.3264 21.6126 43.1498 21.3213 43.2675 20.9718ZM54.2168 15.6129L51.6855 10.1375H51.6266L49.1542 15.6129H54.2168Z" fill="currentColor" />
            <path d="M64.1064 4.60369C64.1064 4.37069 64.283 4.1377 64.5774 4.1377H71.818C74.8791 4.1377 77.3515 6.52589 77.3515 9.49658C77.3515 11.7683 75.821 13.6905 73.584 14.5642L77.0572 20.9133C77.2338 21.2628 77.0572 21.6123 76.6451 21.6123H73.9961C73.7606 21.6123 73.6429 21.4958 73.584 21.3793L70.2286 14.739H67.403V21.0881C67.403 21.3211 67.1675 21.5541 66.9321 21.5541H64.6362C64.3419 21.5541 64.1653 21.3211 64.1653 21.0881V4.60369H64.1064ZM71.5237 12.1178C72.8776 12.1178 74.055 10.9528 74.055 9.55483C74.055 8.21511 72.8776 7.10838 71.5237 7.10838H67.403V12.0595H71.5237V12.1178Z" fill="currentColor" />
            <path d="M90.1261 3.84668C92.6574 3.84668 94.5411 4.66216 96.2482 6.17663C96.4837 6.35138 96.4837 6.70087 96.2482 6.87561L94.7177 8.44833C94.5411 8.62308 94.3056 8.62308 94.0702 8.44833C93.0106 7.51635 91.5977 6.99211 90.1849 6.99211C86.9473 6.99211 84.5337 9.67156 84.5337 12.817C84.5337 15.9624 86.9473 18.5836 90.1849 18.5836C91.7155 18.5836 93.0106 18.0011 94.0702 17.1856C94.2468 17.0109 94.5411 17.0109 94.7177 17.1856L96.3071 18.7584C96.4837 18.9331 96.4837 19.2826 96.3071 19.4573C94.6 21.0883 92.4219 21.9038 90.1849 21.9038C85.1224 21.9038 81.0605 17.9429 81.0605 12.9335C81.0605 7.92409 85.0635 3.84668 90.1261 3.84668Z" fill="currentColor" />
            <path d="M103.96 7.10838H100.074C99.7801 7.10838 99.6035 6.87539 99.6035 6.64239V4.60369C99.6035 4.37069 99.7801 4.1377 100.074 4.1377H111.141C111.436 4.1377 111.612 4.37069 111.612 4.60369V6.64239C111.612 6.87539 111.436 7.10838 111.141 7.10838H107.256V21.1463C107.256 21.3793 107.021 21.6123 106.785 21.6123H104.431C104.195 21.6123 103.96 21.3793 103.96 21.1463V7.10838Z" fill="currentColor" />
            <path d="M116.204 4.60369C116.204 4.37069 116.439 4.1377 116.675 4.1377H119.029C119.265 4.1377 119.5 4.37069 119.5 4.60369V21.1463C119.5 21.3793 119.265 21.6123 119.029 21.6123H116.675C116.439 21.6123 116.204 21.3793 116.204 21.1463V4.60369Z" fill="currentColor" />
            <path d="M134.159 3.84668C136.69 3.84668 138.574 4.66216 140.281 6.17663C140.516 6.35138 140.516 6.70087 140.281 6.87561L138.75 8.44833C138.574 8.62308 138.338 8.62308 138.103 8.44833C137.043 7.51635 135.63 6.99211 134.218 6.99211C130.98 6.99211 128.566 9.67156 128.566 12.817C128.566 15.9624 130.98 18.5836 134.218 18.5836C135.748 18.5836 137.043 18.0011 138.103 17.1856C138.28 17.0109 138.574 17.0109 138.75 17.1856L140.34 18.7584C140.516 18.9331 140.516 19.2826 140.34 19.4573C138.633 21.0883 136.455 21.9038 134.218 21.9038C129.155 21.9038 125.093 17.9429 125.093 12.9335C125.093 7.92409 129.096 3.84668 134.159 3.84668Z" fill="currentColor" />
        </svg>
    )
}

